import React, { useEffect, useState } from 'react';
import Navigation from '../components/navigation/navigation';
import { Helmet } from 'react-helmet';
import { StaticImage, getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { Link } from 'gatsby';
import SpinnerDotPage from '../components/Spinners/SpinnerDotPage';
import OGImage from '../images/favicon-black.png';
import Footer from '../components/footer/pages-footer';
import { URL_API } from 'gatsby-env-variables';

const FreeChapterPage = ({ location }) => {
  const data = useStaticQuery(
    graphql`
      query {
        book_cover_bg: file(relativePath: { eq: "bg-greenblack.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        chapter_bg: file(relativePath: { eq: "default-background.png" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const url = typeof window !== 'undefined' ? window.location.origin : '';
  const params = new URLSearchParams(location.search);
  const bg_book_cover = getImage(data.book_cover_bg);
  const bookHeroBg = convertToBgImage(bg_book_cover);
  const [chapter, setChapter] = useState([]);
  const book = params.get('book');
  const id = params.get('id');
  const [loggedIn, setLoggedIn] = useState(null);
  const [chapterName, setChapterName] = useState('Book Chapter');
  const [pageLoading, setPageLoading] = useState(true);
  const chapterHeroBg = convertToBgImage(data.chapter_bg);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset);
      };
    }
  }, []);

  useEffect(() => {
    setLoggedIn(sessionStorage.getItem('loggedIn'));
  }, []);

  const retrieveChapter = async () => {
    const response = fetch(`${URL_API}/chapter/` + id, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        //'Access-Control-Allow-Origin': "*",
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
        'Content-Type': 'application/json',
        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
        'Access-Control-Allow-Headers':
          'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  useEffect(() => {
    const getChapter = async () => {
      const selectedChapter = await retrieveChapter();
      //console.log(selectedChapter);
      setChapter(selectedChapter);
      setChapterName(selectedChapter.name);
      setPageLoading(false);
    };
    getChapter();
  }, []);

  let chapterBg = '';
  if (chapter.bg_color) {
    chapterBg = chapterHeroBg;
  } else {
    chapterBg = bookHeroBg;
  }

  const chapterBackground = {
    background: `${chapter.bg_color}`,
    position: 'absolute',
    left: '0',
    width: '100%',
    top: '0',
    bottom: '0',
    opacity: '0.9',
    zIndex: '-1',
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${url}/free-chapter`} />
        <meta http-equiv="Content-Type" content="text/xml; charset=UTF-8" />
        <meta property="og:title" content={`${chapterName} | Girar World`} />
        <meta property="description" content="Girar, go on a journey" />
        <meta property="og:description" content="Girar, go on a journey" />
        <meta property="og:url" content={`${url}/free-chapter`} />
        <meta property="og:image" content={`${url}${OGImage}`} />
        <meta name="twitter:url" content={`${url}/free-chapter`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@WeltgeistKiran" />
        <meta name="twitter:title" content={`${chapterName} | Girar World`} />
        <meta name="twitter:description" content="Girar, go on a journey" />
        <meta property="twitter:image" content={`${url}${OGImage}`} />
        <meta property="og:type" content="website" />
        <title>{`${chapterName}`} | Girar World</title>
        <link rel="stylesheet" href="//cdn.quilljs.com/1.2.6/quill.snow.css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Rokkitt:wght@400;500;600;700;800;900&family=Telex&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Navigation />
      {pageLoading ? (
        <SpinnerDotPage />
      ) : (
        <BackgroundImage
          Tag="section"
          className="bg-center"
          // Spread bgImage into BackgroundImage:
          // bg-mi-hero bg-cover bg-center
          {...bookHeroBg}>
          <section className="w-full max-w xl:px-5 px-6 xl:pb-0 pb-10 pt-28 quill-editor">
            {chapter.bg_color ? <div style={chapterBackground} id="background-overlay"></div> : null}
            <div
              className={
                offset >= 150
                  ? 'transition-all duration-3500 opacity-0 ease-in-out delay-200 xl:translate-y-[160px] 2xl:translate-y-[200px]'
                  : 'transition-all duration-500 grid md:grid-cols-12 xl:translate-y-[160px] 2xl:translate-y-[200px]'
              }>
              <div className="w-full col-span-12 max-w-screen-lg mx-auto">
                <h3 className="font-rokkit font-bold xl:mb-7 mb-4 text-white uppercase text-center xl:text-6xl text-2xl">{book}</h3>
                <h3 className="font-rokkit font-bold xl:mb-2 mb-0 text-white uppercase text-center xl:text-3xl text-lg">{chapter.name}</h3>
                <h4 className="font-rokkit font-bold italic xl:mb-7 mb-0 text-white uppercase text-center xl:text-xl text-base">
                  {chapter.excerpt}
                </h4>
              </div>
            </div>
            <div className="grid md:grid-cols-12 transition-all opacity-1 duration-1000 xl:mt-[250px] 2xl:mt-[390px]">
              <div className="w-full col-span-12 max-w-screen-lg mx-auto">
                <div className="chapter-contents xl:px-[15px] px-0 xl:mt-12 mt-0 ql-editor">
                  {/* {chapter.content} */}
                  <div className="text-white font-opensans leading-8 mb-10" dangerouslySetInnerHTML={{ __html: chapter.content }}></div>
                </div>
                <div className="w-full text-center xl:py-10">
                  <h3 className="button-white font-bold font-opensans text-white xl:text-5xl text-3xl text-center mt-5">
                    Wish to <span className="text-apache-800">continue</span> reading?
                  </h3>
                  <a href="/sign-up">
                    <button className="button-white font-bold font-opensans rounded-md mx-auto xl:text-xl text-lg xl:py-3 py-2 px-7 mt-5 transition-all duration-500">
                      Sign up for free
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </BackgroundImage>
      )}

      <Footer />
    </>
  );
};

export default FreeChapterPage;

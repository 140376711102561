import React from 'react';
import { Link } from 'gatsby';

const PageFooter = () => {
  return (
    <>
      <div className="border-b-8 border-apache bg-white">
        <div className="grid xl:max-w-screen-xl max-w-full mx-auto grid-cols-12 xl:gap-x-8 w-full xl:pt-16 pt-20 p-5">
          <div className="xl:col-span-4 col-span-12 xl:text-left text-center xl:pl-12">
            <Link to="/" className="hover:underline">
              <h3 className="font-rokkit text-brown-50 text-3xl uppercase font-bold xl:text-left text-center">Girar</h3>
            </Link>
            <p className="text-base font-opensans pt-5">A GLOBAL STORY.</p>
            <p className="text-base font-opensans">
              Ten years. 365 cultures. The idea of a mother and father, taking the time to accept their son for who he is.
            </p>
            <br className="xl:block hidden" />
            <br className="xl:block hidden" />
            <p className="text-base xl:text-left text-center font-opensans">
              Copyright © {new Date().getFullYear()} Girar World. All rights reserved.
            </p>
          </div>
          <div className="xl:col-span-4 col-span-12 xl:text-left text-center px-12 hidden xl:block"></div>
          <div className="xl:col-span-4 col-span-12 gap-2 xl:border-l xl:text-left text-center">
            <div className="grid grid-cols-12 xl:pl-16 px-5 py-2">
              <div className="xl:col-span-6 col-span-12">
                <p className="text-xl mb-3">
                  <strong>Quick Links</strong>
                </p>
                <p className="text-base mb-3 font-opensans hover:underline">
                  <Link to="/about">About</Link>
                </p>
                <p className="text-base mb-3 font-opensans hover:underline">
                  <Link to="/refund-and-cancellation-policy" className="text-nowrap">
                    Refund & Cancellation
                  </Link>
                </p>
                <p className="text-base mb-3 font-opensans hover:underline">
                  <Link to="/privacy-policy" className="no-wrap">
                    Privacy Policy
                  </Link>
                </p>
                <p className="text-base mb-3 font-opensans hover:underline">
                  <Link to="/terms-and-conditions" className="no-wrap">
                    Terms & Conditions
                  </Link>
                </p>

                <p className="text-base mb-3 font-opensans hover:underline">
                  <Link to="/contact-us" className="no-wrap">
                    Contact Us
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageFooter;
